import React from "react";

export const Resume = () => {
  return (
    <div>
      <iframe
        src={"https://docs.google.com/document/d/1SnOd2XFZ5R57-4j9XHfK6e0uchLfEwpYYDJxUkS1HV8/edit?usp=sharing"}
        title="file"
        width="100%"
        height="1300"
      ></iframe>
    </div>
  );
};

